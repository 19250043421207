<template>
    <div>
        <v-card>
            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                        TICK LIVING
                                    </div>
                                </v-card-title>
								<v-row class="pa-4">
                                    <v-menu offset-y v-if="brokerMap.length > 1" :disabled="initializeLoading">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(item, index) in this.brokerMap" :key="index"
                                                @click="changeBroker(item.broker, item.broker_id)">
                                                <v-list-item-title>{{ item.broker }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
								</v-row>

								<v-row class="pa-4">
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
											<p class="mb-0">The tick info </p>
										</v-alert>
									</v-col>
								</v-row>
                                <v-data-table
                                    dense
                                    :headers="header"
                                    :items="wbdata"
                                    :items-per-page="15"
                                    :search="search"
                                    :loading="initializeLoading"
                                    loading-text="Loading... Please wait"

                                >
                                <template v-slot:top>
                                    <v-text-field
                                    v-model="search"
                                    label="Search"
                                    class="mx-4"
                                    ></v-text-field>
                                </template>
                                <template v-slot:item.Symbol="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >{{item.Symbol}}</span>
                                        </template>
                                        <v-data-table
                                            dense
                                            :headers="hideDataHeader"
                                            :items="[item]"
                                            hide-default-footer
                                            :items-per-page="-1"
                                        >

                                        </v-data-table>

                                    </v-tooltip>
                                </template>

                                <template v-slot:item.Bid="{ item }">
                                <v-chip
                                    v-model="compares"
                                    dark
                                    small
                                    label
                                >
                                    <v-icon left  :color="compares[item.Symbol].Bid">
                                        {{compares[item.Symbol].Bid === 'red' ?
                                        "mdi-arrow-down" :  compares[item.Symbol].Bid==='green' ? "mdi-arrow-up" : ''}}
                                    </v-icon>

                                    {{ item.Bid}}
                                </v-chip>
                                </template>

                                <template v-slot:item.Ask="{ item }">
                                    <v-chip
                                        v-model="compares"
                                        dark
                                        small
                                        label
                                    >
                                        <v-icon left  :color="compares[item.Symbol].Ask">
                                            {{compares[item.Symbol].Ask === 'red' ?
                                            "mdi-arrow-down" :  compares[item.Symbol].Ask==='green' ? "mdi-arrow-up" : ''}}
                                        </v-icon>

                                        {{ item.Ask}}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import { snackbar } from "@components/mixins/snackbar";

export default {
    computed: {
        ...mapState(["brokerMap"])
    },
    mixins: [snackbar],

    data () {
        return {
            wbdata: [],
            header: [],
            hideDataHeader: [],
            selectedBroker: "",
            selectedBrokerId: 2,
            search: "",
            baseURL: "wss://socket.sigmarisk.com.au/",
            url: "",
            connection: null,
            showkeys: ["Symbol", "LastTime", "Bid", "Ask", "High", "Low"],
            compares: {},
            initializeLoading: true,
            stockinterval: Object,
            connectionstate: false
        };
    },
    methods: {
        sendMessage () {
            this.connection.send("hello");
        },
        createHeader () {
            // console.log(this.wbdata)
            var tempheader = [];
            this.showkeys.forEach(key => {
                tempheader.push({ text: key, value: key });
            });
            this.header = tempheader;

            var temphidden = [];
            // console.log(this.wbdata[0])

            for (const key in this.wbdata[0]) {
                if (!(this.showkeys.includes(key))) {
                    temphidden.push({ text: key, value: key });
                }
            }
            this.hideDataHeader = temphidden;
        },
        getColor (comparenum) {
            if (comparenum === -1) {
                return "red";
            } else if (comparenum === 1) {
                return "green";
            } else {
                return "";
            }
        },
        changeBroker (broker, brokerId) {
            this.initializeLoading = true;
            this.wbdata = [];
            this.selectedBrokerId = brokerId;
            this.selectedBroker = broker;
            this.url = this.baseURL + broker.toLowerCase();
            console.log(this.url);
            this.connection.close();
            this.setupConnection();
        },
        setupConnection () {
            this.connection = new WebSocket(this.url);
            const self = this;
            // console.log(this.connection)
            this.connection.onopen = function (event) {
                console.log(event);
                console.log("Successfully connected to the echo websocket server...");
                connectionstate = true;
                self.stockinterval = setInterval(() => {
                    self.sendMessage();
                }, 1500);
            };
            this.connection.onmessage = (event) => {
                var objdata = JSON.parse(event.data);
                this.wbdata = objdata;
                this.wbdata.sort((a, b) => {
                    return a.Symbol.localeCompare(b.Symbol);
                });
                this.createHeader();
                this.initializeLoading = false;
            };
            this.connection.onclose = function (event) {
                console.log(event);
            };

            this.connection.onerror = function (event) {
                console.log(event)
                self.snackBarDanger("Stream break down. Try to reconnect.");
                self.initializeLoading = false;
                clearInterval(this.stockinterval);
            };

            // if (this.connectionstate  === true) {
            // this.stockinterval = setInterval(() => {
            //     this.sendMessage();
            // }, 1500);
            // } else {
            // this.initializeLoading = false
            // }
        }
    },

    created () {
        console.log("Starting connection to WebSocket Server");
        console.log(this.brokerMap[0].broker.toLowerCase());
        const brokername = this.brokerMap[0].broker.toLowerCase();
        this.url = this.baseURL + brokername;
        this.selectedBroker = this.brokerMap[0].broker;
        console.log(this.url);

        this.setupConnection();

        console.log(this.connection);
    },
    watch: {
        wbdata (nv, ov) {
            var res = {};
            if (ov.length === undefined || ov.length === 0 || ov.undefined) {
                var flag = true;
            }
            for (let i = 0; i < nv.length; i++) {
                const compares = {};

                if (!flag) {
                    var BidC = nv[i].Bid.toString().localeCompare(ov[i].Bid.toString());
                    var AskC = nv[i].Ask.toString().localeCompare(ov[i].Ask.toString());
                } else {
                    BidC = 0;
                    AskC = 0;
                }
                BidC = this.getColor(BidC);
                AskC = this.getColor(AskC);

                compares.Bid = BidC;
                compares.Ask = AskC;

                res[nv[i].Symbol] = compares;
            }
            this.compares = res;
            // console.log(this.compares)
            // console.log(this.compares['A50'])
        }
    },

    mounted () {
    },

    destroyed () {
        this.connection.close();
        clearInterval(this.stockinterval);
    }
};
</script>

<style>

</style>
